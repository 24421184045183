import React, { useState } from "react"
import "./PopularSearch.scss"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { Container, Row, Col } from "react-bootstrap"
import { isMobileOnly } from "react-device-detect"

import HTMLReactParser from "html-react-parser"

import ResidentialPopularSearch from "./ResidentialPopularSearch"
import CommercialPopularSearch from "./CommercialPopularSearch"
import NewhomesPopularSearch from "./NewhomesPopularSearch"
import AuctionsPopularSearch from "./AuctionsPopularSearch"
import OfficeDetPopularSearch from "./OfficeDetPopularSearch"

const PopularSearch = (props) => {
  const [state, setState] = useState(null)

  const clickHandler = index => {
    if (state === index) {
      setState(null)
    } else {
      setState(index)
    }
  }

  return (
    <>
    {(props?.SearchType === "Search_Result" || props?.SearchType === "Property_Details" || props?.SearchType === "Office_Details" || props?.Popular_Search_List?.length > 0) && 
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          className="popular-search-component"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}
        >
          {/* {!isMobileOnly ? (
            <Container className="popularsearch-container">
              <Row>
                <Col>
                  <motion.h1 variants={contentItem}>
                    Browse properties
                  </motion.h1>

                  <motion.div className="popular-link-list">
                    { props?.Popular_Search_List?.map((item, index) => {
                      return(
                        <div className="list-items">
                          {item?.popular_search?.Content && HTMLReactParser(item?.popular_search?.Content)}
                        </div>
                      )
                    })}
                  </motion.div>
                </Col>
              </Row>
            </Container>
          ) : (
            <Container>
              <motion.h1 variants={contentItem} style={{ color: "#0C2340" }}>
                Browse properties in Balbriggan, Dublin
              </motion.h1>
              {mobileSearch.map((item, i) => (
                <PopularSearchMobile
                  i={i}
                  clickHandler={clickHandler}
                  {...item}
                  Label={item.Label}
                  //   Url={item.Url}
                  state={state}
                  Sub_Footer_menu={item.Sub_Footer_menu}
                />
              ))}
            </Container>
          )} */}

          {((props?.SearchType === "Search_Result" || props?.SearchType === "Property_Details") && (props?.department === "residential"||props?.department==="NH")) ?
             <ResidentialPopularSearch searchParams={props.searchParams} status={props.status} SearchType={props?.SearchType} department={props?.department} areaName={props.areaName} />
          : ((props?.SearchType === "Search_Result" || props?.SearchType === "Property_Details") && props?.department === "commercial") ?
            <CommercialPopularSearch searchParams={props.searchParams} status={props.status} SearchType={props?.SearchType} department={props?.department} areaName={props.areaName} />
          : ((props?.SearchType === "Search_Result" || props?.SearchType === "Property_Details") && props?.department === "newdevelopments") ?
            <NewhomesPopularSearch searchParams={props.searchParams} status={props.status} SearchType={props?.SearchType} department={props?.department} areaName={props.areaName} />
          : ((props?.SearchType === "Search_Result" || props?.SearchType === "Property_Details") && props?.department === "auction_residential") ?
            <AuctionsPopularSearch searchParams={props.searchParams} status={props.status} SearchType={props?.SearchType} department={props?.department} areaName={props.areaName} />
          : (props?.SearchType === "Office_Details") ?
            <OfficeDetPopularSearch searchParams={props.searchParams} SearchType={props?.SearchType} areaName={props.areaName} />

          : props?.Popular_Search_List?.length > 0 &&
          <Container className="popularsearch-container">
            <Row>
              <Col>
                <motion.h1 variants={contentItem}>
                  {!isMobileOnly ? "POPULAR SEARCHES" : "POPULAR SEARCHES" }
                </motion.h1>

                <motion.div className="popular-link-list">
                  { props?.Popular_Search_List?.map((item, index) => {
                    return(
                      <div className="list-items">
                        {/* <h4>By Property Type</h4> */}
                        {item?.popular_search?.Content && HTMLReactParser(item?.popular_search?.Content)}
                        {/* <div className="horizontal-line"></div> */}
                      </div>
                    )
                  })}
                </motion.div>
              </Col>
            </Row>
          </Container>
          }

        </motion.div>
      )}
    </InView>
    }
  </>
  )
}

export default PopularSearch
