import React, { useState } from "react"
import { Link } from "gatsby"
import { URLS } from "../../../common/site/constants"
import { capitalize } from "../../../common/propertyUtils"
import { PROPERTY_TYPES_H1 } from "../../../property-search/utils"

const DynamicPropertyTypes = ({
  srch_link,
  areas_list,
  status,
  areas,
  areas_slug,
  static_page,
  search_area,
  property_type,
  department,
}) => {
  var get_area_list = []

  console.log("typeof_search_area", typeof search_area, search_area)
  if (typeof search_area === "object") {
    get_area_list = areas_list.filter(
      item =>
        !search_area?.some(item2 =>
          item.replace("Co. ", "").toLowerCase().includes(item2)
        )
    )
  } else {
    get_area_list = areas_list.filter(
      item => !search_area?.includes(item.replace("Co. ", "").toLowerCase())
    )
  }

  var prop_type = PROPERTY_TYPES_H1[property_type.replace(/-/g, "_")]

  var property_type_text =
    department === "newdevelopments"
      ? "New " + prop_type
      : capitalize(prop_type)

  const LinkRender = ({link, label}) => {
    return(
      <a href={link}>{label}</a>
    )
  }

  const linkHandler = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  // const LinkRender = ({ link, label }) => {
  //   return (
  //     <Link to={link} onClick={() => linkHandler()}>
  //       {label}
  //     </Link>
  //   )
  // }

  return (
    <>
      {get_area_list.length > 0 && (
        <ul>
          {get_area_list.map((item, index) => {
            var area_name = item.replace("Co. ", "").toLowerCase()
            var link = `${srch_link}/in-${area_name}/type-${property_type}/`
            var label = `${property_type_text} ${status} in ${item}`

            //console.log("link ===>", link)

            return (
              //<Link to={link}>{property_type_text} {status} in {item}</Link>
              <li>
                <LinkRender link={link} label={label} />{" "}
              </li>
            )
          })}

          {department !== "commercial" &&
            department !== "newdevelopments" &&
            (static_page === "valuation" ? (
              <li>
                <Link to={`${URLS.Valuation.alias}/`}>
                  Property valuation in {capitalize(areas)}
                </Link>
              </li>
            ) : (
              <li>
                <Link
                  to={`${URLS.Office.alias}/`}
                //  onClick={sessionStorage.setItem("searchvalue", areas)}
                >
                  Estate agents in {capitalize(areas)}
                </Link>
              </li>
            ))}
        </ul>
      )}
    </>
  )
}

export default DynamicPropertyTypes
