/**
 * Layout component that queries for datas
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useState, Fragment } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { isSafari, isIOS, isMacOs } from "react-device-detect"
import $ from "jquery"

import "./layout.css"
import Header from "@Components/Header/Header"
import Footer from "@Components/Footer/Footer"
import PopularSearchComponent from "@Components/StaticPageTwo/PopularSearches/PopularSearch"

import {PopularSearch} from "../../queries/common_use_query"
import { Helmet } from "react-helmet"

const Layout = ({ children, header, isBlack, popularSearch, menutype, setFreeTextSearch, Alias }) => {

  const { loading, error, data:popular_search_data } = PopularSearch(Alias ? Alias : "")

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  
  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    // window.addEventListener("scroll", () => {
    //   setScroll(window.scrollY > 60)
    // })
    if (isSafari === true || isIOS === true || isMacOs === true) {
      $("body").addClass("iosdevicesonly")
    }

    
  }, [])

  //console.log("popular_search_data", popular_search_data)

  return (
    <Fragment>
      {Alias==="cookie-policy"&&
      <Helmet>
          <script id="CookieDeclaration" src="https://consent.cookiebot.com/941b8ab4-1127-4282-83ab-d1ece3b6fceb/cd.js" type="text/javascript" async></script>
      </Helmet>
        }
      <Header setFreeTextSearch={setFreeTextSearch}/>
        <main>{children}</main>

        {popular_search_data && popular_search_data?.allMenus?.length > 0 && 
          <PopularSearchComponent Popular_Search_List={popular_search_data?.allMenus[0]?.Popular_Search_Component?.Popular_Search_List} />
        }
      <Footer />
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node.isRequired,
  isBlack: PropTypes.node.isRequired,
  popularSearch: PropTypes.node.isRequired,
}

export default Layout
