import React, { useState } from "react"
import { URLS } from "../../../common/site/constants"
import { capitalize } from "../../../common/propertyUtils"
import { Link } from "gatsby"

const StaticAreas = ({
  srch_link,
  areas_list,
  status,
  areas,
  areas_slug,
  static_page,
  search_area,
  department,
}) => {
  var filter_area_list = []

  if (typeof search_area === "object") {
    filter_area_list = areas_list.filter(
      item =>
        !search_area?.some(item2 =>
          item.replace("Co. ", "").toLowerCase().includes(item2)
        )
    )
  } else {
    filter_area_list = areas_list.filter(
      item => !search_area?.includes(item.replace("Co. ", "").toLowerCase())
    )
  }

  var property_text = "Property"
  if (department === "commercial") {
    property_text = "Commercial property"
  }
  if (department === "newdevelopments") {
    property_text = "New homes"
  }
  if (department === "auction_residential") {
    property_text = "Auction property"
  }

  const LinkRender = ({link, label}) => {
    return(
      <a href={link}>{label}</a>
    )
  }

  const linkHandler = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  // const LinkRender = ({ link, label }) => {
  //   return (
  //     <Link to={link} onClick={() => linkHandler()}>
  //       {label}
  //     </Link>
  //   )
  // }

  return (
    <>
      {filter_area_list.length > 0 && (
        <ul>
          {filter_area_list.map((item, index) => {
            var area_name = item.replace("Co. ", "").toLowerCase()

            var link = `${srch_link}/in-${area_name}/`
            var label = `${property_text} ${status} in ${item}`
            return (
              <li>
                <LinkRender link={link} label={label} />
                {/* <a href={`${srch_link}/in-${area_name}/`}>{property_text} {status} in {item}</a> */}
              </li>
            )
          })}
          {department === "residential" &&
            (static_page === "valuation" ? (
              <li>
                <Link to={`${URLS.Valuation.alias}/`}>
                  Property valuation in {capitalize(areas)}
                </Link>
              </li>
            ) : (
              <li>
                <Link
                  to={`${URLS.Office.alias}/`}
                 // onClick={sessionStorage.setItem("searchvalue", areas)}
                >
                  Estate agents in {capitalize(areas)}
                </Link>
              </li>
            ))}
        </ul>
      )}
    </>
  )
}

export default StaticAreas
