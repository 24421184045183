import React, { useState } from "react"
import { URLS } from "../../../common/site/constants"
import { capitalize } from "../../../common/propertyUtils"
import { PROPERTY_TYPES_H1 } from "../../../property-search/utils"
import { Link } from "gatsby"

const StaticBedrooms = ({
  srch_link,
  status,
  areas,
  areas_slug,
  department,
  property_type,
}) => {
  var bedrooms_list = ["1", "2", "3", "4", "5"]

  var property_text = "property"

  if (department === "newdevelopments") {
    bedrooms_list = ["1", "2", "3", "4", "5", "6"]
    property_text = "new property"
  }

  if (department === "auction_residential") {
    bedrooms_list = ["1", "2", "3", "4", "5", "6"]
    property_text = "auction property"
  }

  var prop_type = property_type
    ? PROPERTY_TYPES_H1[property_type.replace(/-/g, "_")]
    : ""

  if (department === "newdevelopments") {
    prop_type = prop_type ? "new " + prop_type : prop_type
  }

    const LinkRender = ({ link, label }) => {
      return <a href={link}>{label}</a>
    }
  const linkHandler = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  // const LinkRender = ({ link, label }) => {
  //   return (
  //     <Link to={link} onClick={() => linkHandler()}>
  //       {label}
  //     </Link>
  //   )
  // }

  return (
    <>
      {bedrooms_list.length > 0 && (
        <ul>
          {bedrooms_list.map((item, index) => {
            var bedroom_plural = item === "1" ? "bedroom" : "bedrooms"

            var link = `${srch_link}/in-${areas_slug.replace(/ /g, '-')}/${item}-and-more-bedrooms/`
            var label = `${item} ${bedroom_plural} ${property_text} ${status} in ${capitalize(
              areas
            )}`

            if (prop_type) {
              link = `${srch_link}/in-${areas_slug.replace(/ /g, '-')}/type-${property_type}/${item}-and-more-bedrooms/`
              label = `${item} ${bedroom_plural} ${prop_type} ${status} in ${capitalize(
                areas
              )}`
            }

            return (
              <li>
                <LinkRender link={link} label={label} />
              </li>
            )
          })}
          {department === "residential" && (
            <li>
              <a href={`${URLS.Valuation.alias}/`}>
                Property valuation in {capitalize(areas)}
              </a>
            </li>
          )}
        </ul>
      )}
    </>
  )
}

export default StaticBedrooms
