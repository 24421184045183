import React, { useState } from "react"
import { URLS } from "../../../common/site/constants"
import {
  capitalize,
  resiSalesPropertyType,
} from "../../../common/propertyUtils"
import { PROPERTY_TYPES_H1, pTypeBase } from "../../../property-search/utils"
import {Link} from "gatsby"
import _ from "lodash"

const StaticPages = ({ areas, areas_slug }) => {
  return (
    <ul>
      <li><Link to={`${URLS.Office.alias}/`}>Estate agents in {capitalize(areas)}</Link></li>
      
      <li>
        <a href={`${URLS.Valuation.alias}/`}>
          Property valuation in {capitalize(areas)}
        </a>
      </li>
      <li>
        <a href={`/auction-property/for-sale/in-${_.toLower(areas_slug.replace(/ /g, '-'))}/`}>
          Property auction in {capitalize(areas)}
        </a>
      </li>
      <li>
        <a href={`${URLS.ABOUT.alias}/`}>About DNG estate agents</a>
      </li>
      <li>
        <a href={`${URLS.NEWS.alias}/`}>Latest property news</a>
      </li>
      {/* <li><a href={`${URLS.Office.alias}/`}>[area/postcode] area guide</a></li> */}
    </ul>
  )
}

export default StaticPages
