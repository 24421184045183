import React, { useState } from "react"
import {
  contentItem,
} from "@Components/utils/animations"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { Container, Row, Col } from "react-bootstrap"
import { isMobileOnly } from "react-device-detect"
import HTMLReactParser from "html-react-parser"

import { URLS } from "../../common/site/constants"
import { PopularSearch_StaticAreas, PopularSearch_PropertyTypes } from "../../common/propertyUtils"

import StaticAreasSearch from "./Section/StaticAreasSearch"
import StaticPropertyTypes from "./Section/StaticPropertyTypes"
import DynamicPropertyTypes from "./Section/DynamicPropertyTypes"
import StaticPages from "./Section/StaticPages"

const OfficeDetPopularSearch = ({searchParams, SearchType}) => {

  const areaName=searchParams.areas.replace("dng","DNG")

  var areas = typeof searchParams?.areas === "object" ? searchParams?.areas?.join(" and ") : searchParams?.areas ? searchParams?.areas : "ireland";
  let areas_slug = typeof searchParams?.areas === "object" ? searchParams?.areas?.join("-and-") : searchParams?.areas ? searchParams?.areas.replace(/ /g, '-') : "ireland";
  if(areas_slug==="dng-fairview"){
    areas_slug="fairview"
  }
  var status = "for sale";
   
  
 // console.log("searchParams popularsearch ", searchParams?.areas, areas, PopularSearch_StaticAreas.area_list_1, area_list)

  return (
    <>
    <Container className="popularsearch-container">
    <Row>
        <Col>
        <h6 className="footer-popular-search-title">
          {!isMobileOnly ? `Browse properties in ${areas}` : `POPULAR PROPERTY SEARCHES: ${areas}` }
        </h6>

        <motion.div className="popular-link-list">
            <div className="list-items">
                <StaticPropertyTypes srch_link={"/property/for-sale"} status={status} areas={areaName} areas_slug={areas_slug}  property_types={PopularSearch_PropertyTypes.property_types} static_page = "propert_page" />
            </div>
        
            <div className="list-items">
                <StaticPropertyTypes srch_link={"/property/to-rent"} status={"to rent"} areas={areaName} areas_slug={areas_slug} property_types={PopularSearch_PropertyTypes.property_types} static_page = "propert_page" />
            </div>

            <div className="list-items">
                <StaticPages areas={areaName} areas_slug={areas_slug} />
            </div>
        </motion.div>
        </Col>
    </Row>
    </Container>

  </>
  )
}

export default OfficeDetPopularSearch
