import React, { useState } from "react"
import { contentItem } from "@Components/utils/animations"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { Container, Row, Col } from "react-bootstrap"
import { isMobileOnly } from "react-device-detect"
import HTMLReactParser from "html-react-parser"

import { URLS } from "../../common/site/constants"
import {
  PopularSearch_StaticAreas,
  PopularSearch_PropertyTypes,
} from "../../common/propertyUtils"

import StaticAreasSearch from "./Section/StaticAreasSearch"
import StaticBedrooms from "./Section/StaticBedrooms"
import StaticPropertyTypes from "./Section/StaticPropertyTypes"
import DynamicBedrooms from "./Section/DynamicBedrooms"
import DynamicPropertyTypes from "./Section/DynamicPropertyTypes"

const ResidentialPopularSearch = ({ searchParams, status, SearchType, areaName }) => {
  //console.log("searchParams areas ==>", typeof searchParams?.areas);

  var areas =
    typeof searchParams?.areas === "object"
      ? searchParams?.areas?.join(" and ")
      : searchParams?.areas
      ? searchParams?.areas
      : "ireland"
  var areas_slug = typeof searchParams?.areas === "object" ? searchParams?.areas?.join("-and-").toLowerCase() : searchParams?.areas
      ? searchParams?.areas.replace(/ /g, '-').toLowerCase() : "ireland"

  var bedrooms = searchParams?.bedrooms ? searchParams?.bedrooms : ""
  var search_type = searchParams?.pType ? searchParams?.pType : ""
  
  var status =
    status.toLowerCase() === "to let"
      ? "to rent"
      : status.toLowerCase() === "sale agreed"
      ? "for sale"
      : status.toLowerCase() === "let agreed"
      ? "to rent"
      : status.toLowerCase() === "sold"
      ? "for sale"
      : status.toLowerCase() === "withdrawn"
      ? "for sale"
      : status.toLowerCase() === "underofferunavailable"
      ? "for sale"
      : status.toLowerCase()
  var price = searchParams?.price ? searchParams?.price : ""
  var size = searchParams?.size ? searchParams?.size : ""
  var property_type = searchParams?.type ? searchParams?.type : ""

  var srch_link = "/property/" + status.replace(/ /g, "-")

  // console.log("searchParams popularsearch ", searchParams?.areas, areas, PopularSearch_StaticAreas.area_list_1, area_list)

  return (
    <>
      <Container className="popularsearch-container">
        <Row>
          <Col>
            <h6 className="footer-popular-search-title">
              {!isMobileOnly
                ? `Browse properties in ${areaName}`
                : `POPULAR PROPERTY SEARCHES: ${areaName}`}
            </h6>

            <motion.div className="popular-link-list">
              {property_type && (price.min || price.max) ? (
                <>
                  <div className="list-items">
                    <DynamicPropertyTypes
                      srch_link={srch_link}
                      areas_list={PopularSearch_StaticAreas.area_list_5}
                      status={status}
                      areas={areaName}
                      areas_slug={areas_slug}
                      search_area={searchParams?.areas}
                      property_type={property_type}
                    />
                  </div>
                  <div className="list-items">
                    <StaticBedrooms
                      srch_link={srch_link}
                      status={status}
                      areas={areaName}
                      areas_slug={areas_slug}
                      search_area={searchParams?.areas}
                      property_type={property_type}
                    />
                  </div>
                  <div className="list-items">
                    <StaticPropertyTypes
                      srch_link={srch_link}
                      status={status}
                      areas={areaName}
                      areas_slug={areas_slug}
                      search_area={searchParams?.areas}
                      property_type={property_type}
                      static_page="propert_page"
                      property_types={
                        PopularSearch_PropertyTypes.property_types
                      }
                    />
                  </div>
                </>
              ) : bedrooms && (price.min || price.max) ? (
                <>
                  <div className="list-items">
                    <DynamicBedrooms
                      srch_link={srch_link}
                      areas_list={PopularSearch_StaticAreas.area_list_1}
                      status={status}
                      areas={areaName}
                      areas_slug={areas_slug}
                      bedrooms={bedrooms}
                      search_area={searchParams?.areas}
                    />
                  </div>
                  <div className="list-items">
                    <StaticAreasSearch
                      srch_link={srch_link}
                      areas_list={PopularSearch_StaticAreas.area_list_1}
                      status={status}
                      areas={areaName}
                      areas_slug={areas_slug}
                      search_area={searchParams?.areas}
                      static_page="valuation"
                    />
                  </div>
                  <div className="list-items">
                    <StaticPropertyTypes
                      srch_link={srch_link}
                      status={status}
                      areas={areaName}
                      areas_slug={areas_slug}
                      search_area={searchParams?.areas}
                      property_type={property_type}
                      property_types={
                        PopularSearch_PropertyTypes.property_types
                      }
                    />
                  </div>
                </>
              ) : bedrooms && property_type ? (
                <>
                  <div className="list-items">
                    <DynamicBedrooms
                      srch_link={srch_link}
                      areas_list={PopularSearch_StaticAreas.area_list_6}
                      status={status}
                      areas={areaName}
                      areas_slug={areas_slug}
                      bedrooms={bedrooms}
                      search_area={searchParams?.areas}
                      property_type={property_type}
                    />
                  </div>
                  <div className="list-items">
                    <DynamicPropertyTypes
                      srch_link={srch_link}
                      areas_list={PopularSearch_StaticAreas.area_list_3}
                      status={status}
                      areas={areaName}
                      areas_slug={areas_slug}
                      search_area={searchParams?.areas}
                      property_type={property_type}
                      static_page={"valuation"}
                    />
                  </div>
                  <div className="list-items">
                    <StaticPropertyTypes
                      srch_link={srch_link}
                      status={status}
                      areas={areaName}
                      areas_slug={areas_slug}
                      search_area={searchParams?.areas}
                      property_type={property_type}
                      static_page="propert_page"
                      property_types={
                        PopularSearch_PropertyTypes.property_types
                      }
                    />
                  </div>
                </>
              ) : price.min || price.max ? (
                <>
                  <div className="list-items">
                    <StaticAreasSearch
                      srch_link={srch_link}
                      areas_list={PopularSearch_StaticAreas.area_list_4}
                      status={status}
                      areas={areaName}
                      areas_slug={areas_slug}
                      search_area={searchParams?.areas}
                    />
                  </div>
                  <div className="list-items">
                    <StaticBedrooms
                      srch_link={srch_link}
                      status={status}
                      areas={areaName}
                      areas_slug={areas_slug}
                      search_area={searchParams?.areas}
                    />
                  </div>
                  <div className="list-items">
                    <StaticPropertyTypes
                      srch_link={srch_link}
                      status={status}
                      areas={areaName}
                      areas_slug={areas_slug}
                      search_area={searchParams?.areas}
                      property_type={property_type}
                      property_types={
                        PopularSearch_PropertyTypes.property_types
                      }
                    />
                  </div>
                </>
              ) : property_type ? (
                <>
                  <div className="list-items">
                    <DynamicPropertyTypes
                      srch_link={srch_link}
                      areas_list={PopularSearch_StaticAreas.area_list_3}
                      status={status}
                      areas={areaName}
                      areas_slug={areas_slug}
                      search_area={searchParams?.areas}
                      property_type={property_type}
                    />
                  </div>
                  <div className="list-items">
                    <StaticBedrooms
                      srch_link={srch_link}
                      status={status}
                      areas={areaName}
                      areas_slug={areas_slug}
                      search_area={searchParams?.areas}
                      property_type={property_type}
                    />
                  </div>
                  <div className="list-items">
                    <StaticPropertyTypes
                      srch_link={srch_link}
                      status={status}
                      areas={areaName}
                      areas_slug={areas_slug}
                      search_area={searchParams?.areas}
                      static_page="propert_page"
                      property_type={property_type}
                      property_types={
                        PopularSearch_PropertyTypes.property_types
                      }
                    />
                  </div>
                </>
              ) : bedrooms ? (
                <>
                  <div className="list-items">
                    <DynamicBedrooms
                      srch_link={srch_link}
                      areas_list={PopularSearch_StaticAreas.area_list_2}
                      status={status}
                      areas={areaName}
                      areas_slug={areas_slug}
                      bedrooms={bedrooms}
                      search_area={searchParams?.areas}
                    />
                  </div>
                  <div className="list-items">
                    <StaticAreasSearch
                      srch_link={srch_link}
                      areas_list={PopularSearch_StaticAreas.area_list_2}
                      status={status}
                      areas={areaName}
                      areas_slug={areas_slug}
                      static_page="valuation"
                      search_area={searchParams?.areas}
                    />
                  </div>
                  <div className="list-items">
                    <StaticPropertyTypes
                      srch_link={srch_link}
                      status={status}
                      areas={areaName}
                      areas_slug={areas_slug}
                      search_area={searchParams?.areas}
                      property_types={
                        PopularSearch_PropertyTypes.property_types
                      }
                    />
                  </div>
                </>
              ) : (
                <>
                 
                  <div className="list-items">
                    <StaticBedrooms
                      srch_link={srch_link}
                      status={status}
                      areas={areaName}
                      areas_slug={areas_slug}
                      search_area={searchParams?.areas}
                    />
                  </div>
                  <div className="list-items">
                    <StaticPropertyTypes
                      srch_link={srch_link}
                      status={status}
                      areas={areaName}
                      areas_slug={areas_slug}
                      search_area={searchParams?.areas}
                      property_types={
                        PopularSearch_PropertyTypes.property_types
                      }
                    />
                  </div>
                  <div className="list-items">
                    <StaticAreasSearch
                      srch_link={srch_link}
                      status={status}
                      areas={areaName}
                      areas_slug={areas_slug}
                      areas_list={PopularSearch_StaticAreas.area_list_1}
                      search_area={searchParams?.areas}
                    />
                  </div>
                </>
              )}
            </motion.div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ResidentialPopularSearch
