import React, { useState } from "react"
import { URLS } from "../../../common/site/constants"
import { Link } from "gatsby"
import { capitalize } from "../../../common/propertyUtils"
import { PROPERTY_TYPES_H1 } from "../../../property-search/utils"

const DynamicBedrooms = ({
  srch_link,
  status,
  areas_list,
  areas,
  areas_slug,
  search_area,
  static_page,
  bedrooms,
  department,
  property_type,
}) => {
  var get_area_list = []

  if (typeof search_area === "object") {
    get_area_list = areas_list.filter(
      item =>
        !search_area?.some(item2 =>
          item.replace("Co. ", "").toLowerCase().includes(item2)
        )
    )
  } else {
    get_area_list = areas_list.filter(
      item => !search_area?.includes(item.replace("Co. ", "").toLowerCase())
    )
  }

  var property_text = "property"
  if (department === "newdevelopments") {
    property_text = "new property"
  }
  if (department === "auction_residential") {
    property_text = "auction property"
  }

  var prop_type = property_type
    ? PROPERTY_TYPES_H1[property_type.replace(/-/g, "_")]
    : ""

  if (department === "newdevelopments") {
    prop_type = prop_type ? "new " + prop_type : prop_type
  }

  const linkHandler = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }
  const LinkRender = ({link, label}) => {
    return(
      <a href={link}>{label}</a>
    )
  }

  // const LinkRender = ({ link, label }) => {
  //   return (
  //     <Link to={link} onClick={() => linkHandler()}>
  //       {label}
  //     </Link>
  //   )
  // }

  return (
    <>
      {get_area_list.length > 0 && (
        <ul>
          {get_area_list.map((item, index) => {
            var area_name = item.replace("Co. ", "").toLowerCase()
            var bedroom_plural = bedrooms === "1" ? "bedroom" : "bedrooms"

            var link = `${srch_link}/in-${area_name.replace(/ /g, '-')}/${bedrooms}-and-more-bedrooms/`
            var label = `${bedrooms} ${bedroom_plural} ${property_text} ${status} in ${item}`

            if (prop_type) {
              link = `${srch_link}/in-${area_name.replace(/ /g, '-')}/type-${property_type}/${bedrooms}-and-more-bedrooms/`
              label = `${bedrooms} ${bedroom_plural} ${prop_type} ${status} in ${item}`
            }

            return (
              <li>
                <LinkRender link={link} label={label} />{" "}
              </li>
            )
          })}
          {department === "residential" &&
            (static_page === "valuation" ? (
              <li>
                <Link to={`${URLS.Valuation.alias}/`}>
                  Property valuation in {capitalize(areas)}
                </Link>
              </li>
            ) : (
              <li>
                <Link
                  to={`${URLS.Office.alias}/`}
               //   onClick={sessionStorage.setItem("searchvalue", areas)}
                >
                  Estate agents in {capitalize(areas)}
                </Link>
              </li>
            ))}
        </ul>
      )}
    </>
  )
}

export default DynamicBedrooms
