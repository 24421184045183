import React, { useState } from "react"
import { URLS } from "../../../common/site/constants"
import {
  capitalize,
  resiSalesPropertyType,
} from "../../../common/propertyUtils"
import { PROPERTY_TYPES_H1 } from "../../../property-search/utils"
import { Link } from "gatsby"

const StaticPropertyTypes = ({
  srch_link,
  status,
  areas,
  areas_slug,
  static_page,
  property_type,
  property_types,
  department,
}) => {
  //var property_types = ["apartment", "house", "site", "investment", "farm"]

  //console.log("resiSalesPropertyType", capitalize(PROPERTY_TYPES_H1["apartment"]))

  var filter_property_type = property_types

  if (property_type) {
    filter_property_type = property_types.filter(
      item => !item.includes(property_type)
    )
  }

   const LinkRender = ({link, label}) => {
      return(
        <a href={link}>{label}</a>
      )
   }

  const linkHandler = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  // const LinkRender = ({ link, label }) => {
  //   return (
  //     <Link to={link} onClick={() => linkHandler()}>
  //       {label}
  //     </Link>
  //   )
  // }

  return (
    <>
      {filter_property_type.length > 0 && (
        <ul>
          {static_page === "propert_page" && (
            <li>
              <a href={`${srch_link}/in-${areas_slug}/`}>
                Property {status} in {capitalize(areas)}
              </a>
            </li>
          )}

          {filter_property_type.map((item, index) => {
            var prop_type_item = item.replace(/-/g, "_")
            var prop_type_slug = item.replace(/_/g, "-")

            var proptype_name =
              department === "newdevelopments"
                ? `New ${PROPERTY_TYPES_H1[prop_type_item]}`
                : capitalize(PROPERTY_TYPES_H1[prop_type_item])

            var link = `${srch_link}/in-${areas_slug.replace(/ /g, '-')}/type-${prop_type_slug}/`
            var label = `${proptype_name} ${status} in ${capitalize(areas)}`

            return (
              <li>
                <LinkRender link={link} label={label} />
                {/* <a href={`${srch_link}/in-${areas_slug}/type-${prop_type_slug}/`}>{proptype_name} {status} in {capitalize(areas)}</a> */}
              </li>
            )
          })}
          {/* <li><a href={`${URLS.Office.alias}/`}>{capitalize(areas)} area guide</a></li> */}
        </ul>
      )}
    </>
  )
}

export default StaticPropertyTypes
